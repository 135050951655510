import React from "react"
import Shuffle from "shufflejs";
import {Link} from "gatsby";
import {style} from "typestyle";

class ShuffleList extends React.Component {
    constructor(props) {
        super(props);

        const images = [];

        props.items.map((item, index) => (
            images.push({slug: item.node.slug, name: item.node.name ,src: require(`../assets/${item.node.images[0]}`)})
        ))

        this.state = {
            photos: images
        };

        this.element = React.createRef();
        this.sizer = React.createRef();
    }

    componentDidMount() {
        this.shuffle = new Shuffle(this.element.current, {
            itemSelector: '.photo-item',
            easing: 'ease',
            staggerAmount:100,
            gutterWidth: 0,
            isCentered: true

        });

        this._fetchPhotos()
            .then(this._whenPhotosLoaded.bind(this))
            .then((photos) => {
                this.shuffle.resetItems();
            });
    }

    componentDidUpdate() {
        this.shuffle.resetItems();
    }

    componentWillUnmount() {
        if (this.shuffle) {
            this.shuffle.destroy();
            this.shuffle = null;
        }
    }

    _whenPhotosLoaded(photos) {
        return Promise.all(photos.map(photo => new Promise((resolve) => {
            const image = document.createElement('img');
            image.src = photo.src;

            if (image.naturalWidth > 0 || image.complete) {
                resolve(photo);
            } else {
                image.onload = () => {
                    resolve(photo);
                };
            }
        })));
    }

    _fetchPhotos() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this.state.photos);
            }, 300);
        });
    }

    render() {
        const image = style({
            display:'block',
            position: 'relative',

            $nest: {
                'img': {
                    transition: 'filter .3s',
                },

                '&:hover img': {
                    webkitFilter: 'grayscale(100%)',
                    filter: 'grayscale(100%)',
                    opacity: '0.6'
                },

                'span': {
                    transition: 'opacity .3s',
                    opacity: '0',
                    position: 'absolute',
                    bottom:'8px',
                    left: '2px',
                    width: '100%',
                    fontSize:'40px',
                    lineHeight: '39px',
                    textTransform: 'uppercase',
                    color: 'white',
                    textShadow: '-1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey',
                    fontFamily: 'Montserrat, sans-serif'
                },

                '&:hover span': {
                    opacity: '1',
                },
            }
        });

        const title = style({

        });

        return (
            <div className="container" ref={this.element} style={{width:'100%'}}>
                {this.state.photos.map((item, index) => (
                    (<div key={index} className={"photo-item"} style={{width:'25%', overflow:'hidden', position: 'relative'}}>
                        <Link to={item.slug} className={image}>
                            <img src={item.src} alt={item.name} title={item.name} style={{marginBottom:0}} />
                            <span className={title}>{item.name}</span>
                        </Link>
                    </div>)
                ))}
            </div>
        );
    }
}

export default ShuffleList