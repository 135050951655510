import React from "react"
import {graphql, StaticQuery} from "gatsby";
import ShuffleList from "./shuffle";

const List = () => (
    <StaticQuery
        query={graphql`
      {
          allGalleriesJson {
            edges {
              node {
                name
                copyright
                slug
                retouch
                images
              }
            }
          }
        }
    `}
        render={data => (
            (
            <ShuffleList items={data.allGalleriesJson.edges}  />
            )
        )}
    />
)

export default List