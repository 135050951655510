import React from "react"

import Layout from "../components/layout"
import List from "../components/list"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Thomas Seifert - fashion, editorial, beauty, portraits" />

    <List />
  </Layout>
)

export default IndexPage
